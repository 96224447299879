export const programmingQA = [
  {
    id: 1,
    question: "How did you start",
    answer:
      "I began by taking an HTML and CSS course on UDEMY. My mentor Jeremy recommended that I learn JavaScript and not make a decision until I built a BlackJack game using it. So using UDEMY I took mulitiple JavaScript courses in order to better understand it. Then I built my game. He told me that programming is a-lot of smacking your head against a wall but once you get passed it, it can feel great. Being a person who likes to solve puzzles, this drew more interest.",
  },
  {
    id: 2,
    question: "Where did you go from there",
    answer:
      "I started taking more and more courses on UDEMY. React, MYSQL, C++, CSS and SASS, and Node.js. I also got interested in and started courses in Blender, and Unreal Engine.",
  },
  {
    id: 3,
    question: "What courses have you finished",
    answers: [
      {
        id: 1,
        name: "The Modern JavaScript Bootcamp Course",
        link: "https://www.udemy.com/course/javascript-beginners-complete-tutorial/",
      },
      {
        id: 2,
        name: "The Ultimate MySQL Bootcamp: Go from SQL Beginner to Expert",
        link: "https://www.udemy.com/course/the-ultimate-mysql-bootcamp-go-from-sql-beginner-to-expert/",
      },
      {
        id: 3,
        name: "Modern React with Redux",
        link: "https://www.udemy.com/course/react-redux/",
      },
      {
        id: 4,
        name: "Complete React Developer (w/ Redux, Hooks, and GraphQL)",
        link: "https://www.udemy.com/course/complete-react-developer-zero-to-mastery/?utm_source=adwords-pmax&utm_medium=udemyads&utm_campaign=PMax_la.EN_cc.US&utm_content=deal4584&utm_term=_._ag__._kw__._ad__._de_c_._dm__._pl__._ti__._li_9029894_._pd__._&gad_source=1&gclid=CjwKCAjwkuqvBhAQEiwA65XxQHC3fRiQ54N2csIzFel93Q_KdVZ63DL4Q_V6JxyFCAyx5M2V7R-jNxoCrgQQAvD_BwE&couponCode=2021PM20",
      },
      {
        id: 5,
        name: "The Complete JavaScript Course: From Zero to Expert",
        link: "https://www.udemy.com/course/the-complete-javascript-course/",
      },
      {
        id: 6,
        name: "Build Responsive Real-World Websites with HTML and CSS",
        link: "https://www.udemy.com/course/design-and-develop-a-killer-website-with-html5-and-css3/",
      },
      {
        id: 7,
        name: "Effective Time Management - Get 10x More Done in Less Time",
        link: "https://www.udemy.com/course/effective-time-management/",
      },
    ],
  },
  {
    id: 4,
    question: "Are you willing to learn different Languages or Frameworks",
    answer: "Yes!",
  },
];
export const gamingQA = [
  {
    id: 1,
    question: "What are your favorite games",
    answers: [
      { name: "Bloodborne" },
      { name: "Ghosts of Tsushima" },
      { name: "Knights of the old Republic" },
      { name: "Zelda Tears of the Kingdom" },
      { name: "Zelda Ocarina of Time" },
    ],
  },
  {
    id: 2,
    question: "Why is Bloodborne your favorite game",
    answer:
      "Never have I ever been haunted by a game I didn't play. I bought this game when it first came out in 2015. I played it and could not, for the life of me, get past the 2nd boss Father Gascoigne. For years I kept going back to play this game because the atmosphere was so inticing. This game stuck in my head so much until in 2018 after my first semster of college I finally played it continously to get past this part and beat the game! I have played through the story multiple times sense and as of right now is the only game I have 100% completed. The feeling when finally beating a boss was an addictive feeling. Never before had I played a game with such interesting lore, story-telling methods, and atmosphere.",
  },
  {
    id: 3,
    question: "What consoles do you have",
    answers: [
      { name: "N64" },
      { name: "Nintendo Switch" },
      { name: "OG Xbox" },
      { name: "Xbox 360" },
      { name: "PS2" },
      { name: "PS3" },
      { name: "PS4 Pro" },
      { name: "PS5" },
      { name: "PC" },
    ],
  },
  {
    id: 4,
    question: "Why don't you have an Xbox One or Series X",
    answer:
      "I did, but once I was finally able to get a good GPU for my PC I no longer used the Xbox's. I sold them because through gamepass and steam just about every game is playable on pc",
  },
  {
    id: 5,
    question: "What are some other games you love",
    answer:
      "Assasins Creed: Black Flag, Halo Reach, Age of empires 2, Skyrim, Battle for Middle Earth 2, Horizon Zero, Portal, Portal 2, Spider-Man(ps4), Forza, Vanishing of Ethan Carter, Lego starwars, Republic Commando, Spliter Cell: Chaos Theory, Last of us: PT1",
  },
];
export const moviesQA = [
  {
    id: 1,
    question: "What are your favorite movies",

    answers: [
      { name: "The Princess Bride" },
      { name: "The LOTR Trilogy" },
      { name: "Batman: Under the Red Hood" },
      { name: "Godzilla: King of Monsters" },
      { name: "10 Things I Hate About You" },
      { name: "A knights Tale" },
    ],
  },
  {
    id: 2,
    question: "Why not StarWars",
    answer:
      "It used to be at the top of my list of favorite movies, but after the last Jedi it was removed from my favorites list. To me, that movie was not just a bad StarWars movie, It was the worst movie I had ever seen in my entire life. Never had a Movie ruin so many childhood characters and franchise. This movie made the origional Trilogy not matter, and for that I can't ignore it and StarWars was removed from my favoite movies list.",
  },
  {
    id: 3,
    question: "Why The Princess Bride",
    answer:
      "This movie was always a GoTo movie for me, when I was sick or bored all growing up. In High-School I read the book for an english class and began learning more and more about it. To this day this is the only movie I've seen that continues to be entertaining and gets better the more you think about it, the more you watch it. It's the only near-perfect movie I've seen and likely will ever see.",
  },
  {
    id: 4,
    question: "Do you have a favorite Genre",
    answer:
      "No, I can equally enjoy documentaries, comedies, Rom-Coms, Musicals, etc... I believe there are good and bad in every Genre. Like music, enjoy the good ones and don't watch the bad ones.",
  },
  {
    id: 5,
    question: "Do you have a least favorite Movie",
    answer:
      "Yes, while there are many movies nothing is as bad as StarWars: The Last Jedi.",
  },
  {
    id: 6,
    question: "What about Actors",
    answer:
      "There are many great actors, I don't believe any one actor will make or break a movie. They all have good and bad. My favoite is probably Heath Ledger, though I also enjoy many others. Like my favorites my least favorites still have enjoyable movies, generally speaking though I don't generally enjoy Jim Carry, Adam Sandler, or Will Ferrell movies.",
  },
];
export const showsQA = [
  {
    id: 1,
    question: "What are some of your favorite TV-Shows",
    answers: [
      { name: "How I Met Your Mother" },
      { name: "Avatar The Last Airbender" },
      { name: "Psych" },
      { name: "Attack On Titan" },
      { name: "Top Gear" },
      { name: "Grand Tour" },
      { name: "House M.D." },
      { name: "Parks and Rec" },
      { name: "Big Bang Theory" },
      { name: "Arcane" },
      { name: "Suits" },
      { name: "Devil as a Part-Timer" },
      { name: "Star Wars: Rebels" },
      { name: "Star Wars: Clone Wars" },
      { name: "Sword Art Online" },
      { name: "Frieren" },
    ],
  },
  {
    id: 2,
    question: "Why How I Met Your Mother",
    answer:
      "Admittedly not many people would say this was their favorite show. Many people were not happy with the ending. I am one who does however. To me this show is funny, relatable, and actually deals with problems real people deal with. I still remember the first time I saw the episode where Marshal's father died. Why not friends? to me the difference is that friends does't really relate to these kinds of problems. I would say if you're a fan of just plane 'happily ever after', then watch friends. If you enjoy real things, real life then watch HIMYM. I also have a-lot of respect for the writers having an ending in mind and sticking to it, even when it wasn't very popular.",
  },
  {
    id: 3,
    question: "Why ATLA in 2nd",
    answer:
      "Weirdly enough I would actually say that Avatar is a more perfect and better tv show than HIMYM. I just like HIMYM slightly better. Avatar is a flawless show with extraordinary story telling, comedy, and showing relatable problems.",
  },
  {
    id: 4,
    question: "Why such a long list",
    answer:
      "There are a lot of shows I like and some are hard to narrow down. For example I think the when The Office is funny. I think it's better than Parks and Rec, however I think Parks and Rec is more consistantly funnier. There's just so much I enjoy and tend to watch them in the background while working on a project so it's not quiet.",
  },
  {
    id: 5,
    question: "What are your TV-Show hot takes",
    answer:
      "Breaking Bad, and Game of Thrones are not just over-rated but appaulingly terrible shows",
  },
];
export const carsQA = [
  {
    id: 1,
    question: "What is your dream car",
    answer:
      "1969-1972 Chevrolet Chevelle is my dream project car. 2015 Ferrari 488 is my dream super car. 2013-2016 Audi S4 is my 'Realistic' dream car. 2016 Apollo Arrow is my most unrealistic dream car.",
  },
  {
    id: 2,
    question: "What cars have you owned",
    answer:
      "my first car was a 2001 Audi S4 and is my favorite car i've owned. From there I briefly owned a 2015 Kia Optima. I traded that car in for a 2013 Camaro SS which I drove for awhile. Due to the bills that came along with school I had to give that up and now currently drive a 2012 Dodge Avenger SXT.",
  },
  {
    id: 3,
    question: "What car would you like to get next",
    answer:
      "Likely, I'll get a 2013-2016 Audi S4. I'm also tempted to pick up a 2015 Ford Focus ST, A Corvette, or another Camaro. Due to gas prices I'm also tempted towards a Tesla or Cheverolet Volt. Unless I decide to just get a bike instead",
  },
  {
    id: 4,
    question: "What about racing",
    answer:
      "Over the past few years I've started to lightly follow F1 racing. I really like the current rivalry between Lewis Hamilton and Max Verstappen. For the first time I actually watched an entire race, which was the 2021 final race in Abu Dhabi. I really enjoyed it and am counting down the days till the 2026 season. Though I'm not happy with how that particular race ended and hope the people in charge of F1 make some changes for this upcoming season.",
  },
  {
    id: 4,
    question: "Do you have a favorite team",
    answer:
      "Yes, I root for Corvette racing in the GT3-AM racing, and Ferrari in F1",
  },
];
export const miscQA = [
  {
    id: 2,
    question: "Virtual Pinball Cabinet",
    mov: require("../../Assets/Misc/Pinball.MP4"),
    answer:
      "I was introduced to one of these from my Highschool girlfriends dad and decided to try to build one myself. loaded up with over 200 different virtual pinball tables. Using programs like Visual Pinball and PinballFX. Wired up with light up buttons, strobes, cabinet lights, sirens, and a fan. This is one of my most complete project's I've worked on. I did not like how the it was turning out so I sold this one and have begun designs on a new one.",
  },
  {
    id: 3,
    question: "Game Ogre",
    mov: require("../../Assets/Misc/GameOgre.MP4"),
    answer:
      "This is a Virtual Arcade cabinet. This contains over 3000 games and runs everthing, I know of, Nintendo 64 and older. Currently it only works with joystick and button, or controller games. However the aiming gun arcade shooters are currently being developed that will work with the modern screens. I'm hoping to add that capability to this project. I would also like to vynl wrap it with an arcade cabinet like style",
  },
  {
    id: 4,
    question: "Desk",
    img: require("../../Assets/Misc/Desk.jpg"),
    answer:
      "I built this desk to take with me to my apartment when I lived in provo. I wanted the open shelves so I could place a projector on it so I could work at my desk comfortably and still have a TV-Show or movie being projected onto my wall behind the desk.",
  },
  {
    id: 5,
    question: "Table",
    img: require("../../Assets/Misc/Table.jpg"),
    answer:
      "Made out of red-wood this is probably one of the coolest things I've made. Custom made to hold my TV and a shelf to keep my gaming consoles off the carpet.",
  },
  {
    id: 6,
    question: "Wood Engraving",
    img: require("../../Assets/Misc/Engraving.JPG"),
    answer:
      "Starting out by hand, I enjoy doing some pyrography. I like the smell of burning wood and how it looks. More Recently I've invested in a Laser engraver and have created family plaques for my mom and married siblings, wooden Christmas ornaments, and some other things.",
  },
  {
    id: 7,
    question: "UE5",
    img: require("../../Assets/Misc/UE5.png"),
    answer:
      "This is my unnamed UE5 project idea, not yet started. I'm working on learning UE5 and then starting this project",
  },
];
export const outdoorsQA = [
  {
    id: 1,
    question: "What Outdoor activities do you enjoy",
    answers: [
      { name: "Camping" },
      { name: "Motorcycle riding (dirt and street)" },
      { name: "Road Trips" },
      { name: "Canyon Drives" },
      { name: "ATVs" },
      { name: "Rock Climbing" },
    ],
  },
  {
    id: 2,
    question: "Do you have a favorite camping spot",
    answer:
      "yeah, growing up my family would go on a yearly camping trip over the summer with my extended family. We would usually go to a place called Potters Ponds. We would go on 4-Wheeler rides, my favorite is trail #5. We would also have Airsoft wars in the forested areas, and always do a friday night movie, using a projector we would watch a movie while it was dark by the fire.",
  },
];
export const sportsQA = [
  {
    id: 1,
    question: "What Sports",
    answers: [
      { name: "Basketball (Go Jazz)" },
      { name: "Football (Go Saints)" },
      { name: "Golf" },
      { name: "Go Karting" },
    ],
  },
  {
    id: 2,
    question: "Did you ever play any sports competitively",
    answer:
      "Aside from intramural Basketball not in college. In High School I was a QB on the football team until I tore my ACL. I tried out for the Basktball team but did not make it? Until recently, my Dad and I would enter the Bluffdale City golf tournament each year.",
  },
  {
    id: 3,
    question: "Do you play any other Sports",
    answer:
      "I don't get alot of opportunites to play many other sports but I can appreciate most sports and I'm always up for it.",
  },
];
export const familyQA = [
  {
    id: 1,
    question: "How big is your family",
    answer:
      "Which one, I come from a biological family of 9 falling at number 8. I also have a non-biological family as I believe, close friends are just the family you get to choose",
  },
  {
    id: 2,
    question: "How big is your non-bio family",
    answers: [
      {
        name: "Brandon and Elenore Chambers - Brandon was my roomate for 1 semester at snow college and married one of the greatest women in the entire world and we've grown close",
      },
      {
        name: "Lauren Smith - A friend of Elenores who invited me to join their DND group and we've grown close",
      },
      {
        name: "Konlin and Cydnee Gappmayre - Konlin and I worked together at LHM Ford we became roomates for a year and is still one of my closest brothers",
      },
      {
        name: "Andrew Aagard - one of my oldest and most loyal friends since I was 12",
      },
      {
        name: "Steven Skoubye - My oldest and most loyal friend, since we were 5 there is nobody I look up to more in life",
      },
      {
        name: "Zackary McClellan - My 2nd oldest friend Steven, Zack, and I were a trio all growing up in our neighborhood. Zack is one of the realest hardest worker I've ever met",
      },
    ],
  },
  {
    id: 3,
    question: "What makes them Family, not just Friends",
    answer:
      "I would give each of these people an organ with no explanation necessary when asked. Each year we go on a camping trip to Potters Ponds, these are also the people I have grown with the most and I rely on the most",
  },
  {
    id: 4,
    question: "What about your bio family",
    answer:
      "They're ok, I honestly like the in-laws a little more than the actual siblings though ;)",
  },
];
