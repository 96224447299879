const PersonalLinks = [
  {
    id: 1,
    icon: "linkedin-with-circle",
    link: "https://www.linkedin.com/in/josh-dietrich-3b1501230/",
  },
  {
    id: 2,
    icon: "github",
    link: "https://github.com/jdietrich11",
  },
  {
    id: 3,
    icon: "facebook",
    link: "https://www.facebook.com/josh.dietrich.94",
  },
];
export default PersonalLinks;
