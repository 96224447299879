import React from "react";

const References: React.FC = () => {
  return (
    <div className="References">
      <div>Hello World</div>
    </div>
  );
};

export default References;
